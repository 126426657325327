import React from 'react';
import firebase from 'firebase/app';
// import 'firebase/firestore';
import 'firebase/database';


const firebaseConfig = {
  apiKey: "AIzaSyBUjDW7a-aTCcE8L2VJ3PESc44TtbcRN_U",
  authDomain: "expo-demo-spc.firebaseapp.com",
  databaseURL: "https://expo-demo-spc.firebaseio.com",
  projectId: "expo-demo-spc",
  storageBucket: "expo-demo-spc.appspot.com",
  messagingSenderId: "1057156967503",
  appId: "1:1057156967503:web:c2378ad0bed4bcb5808e3a"
};

firebase.initializeApp(firebaseConfig);
// const db = firebase.firestore();
const db = firebase.database();


export default class Messages extends React.Component {
    constructor(props) {
        super(props);
        this.state = { messages: [] };
    }

    componentDidMount() {
        db.ref('messages').on('value',(querySnapshot) => {
            const messages = [];
            querySnapshot.forEach((doc) => {
                messages.push(Object.assign(
                    {},
                    {id: doc.key},
                    doc.val()));
                this.setState({ messages });
            });
        })
    }

    render() {
    const messages = this.state.messages.sort((a, b) => (
        b.timestamp - a.timestamp
    ));
        return (
            <div>
                {messages.map(msg => (
                <div key={msg.id}>
                    {msg.text}
                </div>
                ))}
            </div>
        )
    }
}
